<template>
  <div>
    <b-card>
      <b-card-title class="d-flex justify-content-between align-items-center">
        <h3>Pedidos automáticos</h3>
        <b-button
          variant="success"
          class="rounded-button"
          :to="{
            name: 'add-store-automatic-restocking',
            params: { id: $route.params.id },
          }"
        >
          <feather-icon icon="PlusIcon" size="20" />
        </b-button>
      </b-card-title>
      <b-card-text class="mb-2">
        Un pedido automático es una instrucción para que el sistema solicite
        productos al proveedor cuando el inventario de los mismos llegue a un
        nivel bajo. De esta manera, se puede mantener un inventario optimizado
        sin la necesidad de estar constantemente pendiente de los niveles de
        existencias.
      </b-card-text>
      <b-row>
        <b-col>
          <automatic-restocking-list
            :cancelRestocking="cancelRestocking"
            :daysInSpanish="daysInSpanish"
            :handleSelectedRestocking="handleSelectedRestocking"
          />
          <automatic-restocking-table
            :daysInSpanish="daysInSpanish"
            :cancelRestocking="cancelRestocking"
            :handleRemoveRestokingProduct="handleRemoveRestokingProduct"
            :handleSelectedRestockingProduct="handleSelectedRestockingProduct"
          />
        </b-col>
      </b-row>
      <div class="mx-2 mb-2 mt-2">
        <pagination
          :loading="loading"
          :entriesPerPage.sync="entriesPerPage"
          :handlePagination="handlePagination"
          :pagination="pagination"
        />
      </div>
    </b-card>

    <automatic-restocking-edit-product
      :selectedRestockingProduct="selectedRestockingProduct"
      :handleEditProduct="handleEditProduct"
    />

    <automatic-restocking-info-modal
      :selectedRestocking="selectedRestocking"
      :handleRemoveRestokingProduct="handleRemoveRestokingProduct"
      :handleSelectedRestockingProduct="handleSelectedRestockingProduct"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex"

import AutomaticRestockingTable from "@/@core/components/automatic-restocking/AutomaticRestockingTable.vue"
import AutomaticRestockingList from "@/@core/components/automatic-restocking/AutomaticRestockingList.vue"
import AutomaticRestockingInfoModal from "@/@core/components/automatic-restocking/AutomaticRestockingInfoModal.vue"
import AutomaticRestockingEditProduct from "@/@core/components/automatic-restocking/AutomaticRestockingEditProduct.vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import Pagination from "@/@core/components/Pagination.vue"

import messagesMixin from "@/@core/mixins/messagesMixin"

import { handleAPIErrors } from "@/utils/fetch-utils"

export default {
  name: "AutomaticRestockingView",
  components: {
    Pagination,
    AutomaticRestockingTable,
    AutomaticRestockingList,
    AutomaticRestockingInfoModal,
    AutomaticRestockingEditProduct,
    ToastificationContent,
  },
  mixins: [messagesMixin],
  data() {
    return {
      loading: true,
      entriesPerPage: "10",
      daysInSpanish: {
        monday: "Lunes",
        tuesday: "Martes",
        wednesday: "Miércoles",
        thursday: "Jueves",
        friday: "Viernes",
        saturday: "Sábado",
        sunday: "Domingo",
      },
      selectedRestocking: {},
      selectedRestockingProduct: {},
    }
  },
  watch: {
    entriesPerPage() {
      this.getAutomaticRestockings()
    },
  },

  computed: {
    ...mapGetters("automaticRestocking", ["pagination"]),
  },

  created() {
    this.setStoreId(this.$route.params.id)
  },

  beforeMount() {
    this.getAutomaticRestockings().then((res) => {})
  },
  methods: {
    ...mapMutations("automaticRestocking", ["setStoreId"]),
    ...mapActions("automaticRestocking", ["fetchAutomaticRestockings"]),
    ...mapActions("automaticRestocking", [
      "cancelAutomaticRestocking",
      "removeRestokingProduct",
      "editRestockingProduct",
    ]),

    getAutomaticRestockings({ page, per_page } = {}) {
      this.loading = true
      return this.fetchAutomaticRestockings({
        by_active_status: "active",
        by_establishment: this.$route.params.id,
        meta: {
          pagination: {
            page: page || 1,
            per_page: this.entriesPerPage,
          },
        },
      })
        .then((res) => {
          return res
        })
        .catch((error) => {
          const errors = handleAPIErrors(error.response.data)
          this.errorToast(errors[0])
        })
        .finally(() => {
          this.loading = false
        })
    },

    handleEditProduct(minimun_stock, threshold_inventory) {
      this.loading = true

      this.editRestockingProduct({
        id: this.selectedRestockingProduct.id,
        automatic_restocking: {
          minimum_stock: this.selectedRestockingProduct.minimum_stock,
          threshold_inventory:
            this.selectedRestockingProduct.threshold_inventory,
        },
      })
        .then((response) => {
          this.$bvModal.hide("edit-restocking-product")

          this.successToast("Producto editado")

          this.getAutomaticRestockings().then((res) => {
            this.selectedRestocking = res.data.find(
              (restocking) => restocking.id === this.selectedRestocking.id
            )
          })
        })
        .catch((error) => {
          const errors = handleAPIErrors(error.response.data)
          this.errorToast("Edición del producto falló!", errors[0])
        })
    },

    handleSelectedRestocking(restocking) {
      this.selectedRestocking = restocking
      this.$bvModal.show("automatic-restocking-info-modal")
    },

    handleSelectedRestockingProduct(restocking_product) {
      this.selectedRestockingProduct = {
        ...restocking_product,
        minimum_stock: parseInt(restocking_product.minimum_stock),
        threshold_inventory: parseInt(restocking_product.threshold_inventory),
      }
      this.$bvModal.show("edit-restocking-product")
    },

    handleRemoveRestokingProduct(restocking_product_id) {
      this.loading = true

      this.$swal({
        title: "¿Estás seguro?",
        text: "El producto se eliminará del pedido automático",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, borrar!",
        cancelButtonText: "No, cancelar!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.removeRestokingProduct(restocking_product_id)
            .then((response) => {
              this.successToast("Producto eliminado")

              this.getAutomaticRestockings().then((res) => {
                this.selectedRestocking = res.data.find(
                  (restocking) => restocking.id === this.selectedRestocking.id
                )
              })
            })
            .catch((error) => {
              const errors = handleAPIErrors(error.response.data)
              this.errorToast("Edición del producto falló!", errors[0])
            })
        }
      })
    },

    cancelRestocking(restocking_id) {
      this.loading = true
      this.$swal({
        title: "¿Estás seguro?",
        text: "El pedido se desactivará y dejará de ser recibido por su proveedor",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, cancelar!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.cancelAutomaticRestocking(restocking_id)
            .then((response) => {
              this.successToast("Pedido automático cancelado con exito!")

              this.getAutomaticRestockings()
            })
            .catch((error) => {
              const errors = handleAPIErrors(error.response.data)

              this.errorToast(
                "Cancelación del pedido automático falló!",
                errors[0]
              )
            })
        }
      })
    },

    handlePagination({ page, per_page }) {
      this.getAutomaticRestockings({ page })
    },
  },
}
</script>

<style lang="scss" scoped>
.gutter-sm {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.restocking-table {
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
}

.restocking-list {
  display: block;

  @media (min-width: 768px) {
    display: none;
  }
}

.rounded-button {
  border-radius: 50%;
  padding: 0.5rem;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.1);
  }
}
</style>
